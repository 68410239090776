@use '../../styles/breakpoints.module';

.footer {
  background-color: var(--module-footer-background);

  &__sub-container {
    background: var(--module-footer-bottom-background);
  }

  &__top-inner {
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    flex-flow: column wrap;
    font-size: var(--text-sm);
    margin: 0 auto;
    max-width: var(--util-max-screen-size);
    padding: var(--space-6) var(--space-4);
    row-gap: var(--space-4);

    @include breakpoints.for-medium-up {
      align-items: center;
      display: block;
      flex-direction: row;
      padding: var(--space-6);
      row-gap: 0;
      width: 100%;
    }

    @include breakpoints.for-large-up {
      align-self: center;
      display: flex;
      justify-content: flex-end;
    }
  }

  &__top-country-selector {
    justify-content: flex-start;
    order: 1;
    width: 210px;

    @include breakpoints.for-medium-up {
      margin-bottom: var(--space-4);
    }
    @include breakpoints.for-large-up {
      margin-right: auto;
    }
  }

  &__top-country-selector-button {
    background-color: var(--module-footer-country-selector-background-color);
    border-color: var(--module-footer-country-selector-border-color);
    border-radius: var(--module-footer-country-selector-border-radius);
    color: var(--module-footer-country-selector-color);

    &:hover {
      background-color: var(--module-footer-country-selector-hover-color);
      border-color: var(--module-footer-country-selector-border-color);
      filter: var(--module-footer-country-selector-filter);
    }
  }

  &__top-country-selector-icon {
    margin-left: 0;
    position: absolute;
    right: var(--space-2);
  }

  &__top-country-selector-list {
    border-color: var(--module-footer-country-selector-list-border-color);
    border-radius: var(--module-footer-country-selector-border-radius);
    bottom: 42px;
    font-size: var(--module-footer-country-selector-list-font-size);

    &-item {
      background-color: var(--module-footer-country-selector-list-background);
      border: 1px solid transparent;
      color: var(--module-footer-country-selector-color);

      &:hover {
        background-color: var(--module-footer-country-selector-hover-color);
        border: 1px solid transparent;
        color: var(--module-footer-country-selector-color);
        filter: var(--module-footer-country-selector-filter);
      }
    }
  }

  &__top-country-selector-image {
    overflow: hidden;
    vertical-align: top;
  }

  &__top-country-selector-label {
    margin-left: var(--space-2);
  }

  &__top-navigation {
    order: 3;

    @include breakpoints.for-medium-up {
      display: inline-block;
      flex-direction: row;
    }
  }

  &__top-navigation-link {
    color: var(--module-footer-color);

    &:hover {
      background-color: unset;
      color: var(--module-footer-color);
      text-decoration: underline;
    }
  }

  &__selector-open {
    display: block;
  }

  &__selector-closed {
    display: none;
  }

  &__divider {
    background-color: var(--module-footer-break);
    border: none;
    display: block;
    height: 1px;
    margin: 0;
    overflow: hidden;
    width: 100%;
  }

  &__bottom {
    background: var(--module-footer-bottom-background);
    box-sizing: border-box;
    color: var(--module-footer-color);
    display: flex;
    flex-direction: column;
    line-height: var(--space-6);
    margin: 0 auto;
    max-width: var(--util-max-screen-size);
    padding: var(--space-6) var(--space-6);
    row-gap: var(--space-4);

    @include breakpoints.for-medium-up {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }

    &-link {
      box-sizing: border-box;
      max-width: var(--util-max-screen-size);
      text-decoration: none;
    }

    &-link-inner {
      align-items: center;
      box-sizing: border-box;
      display: flex;
    }

    &-link-image {
      margin-right: var(--space-1);
      vertical-align: top;
    }

    &-link-text {
      color: var(--module-footer-color);
      cursor: pointer;
      font-size: var(--text-sm);
      list-style: none;
      margin-left: 1rem;
      text-decoration: none;
    }

    &-link-text:hover {
      text-decoration: underline;
    }

    &-copyright {
      color: var(--module-footer-copyright-color);
      font-size: var(--text-xs);
      font-weight: normal;
      line-height: var(--space-6);
      margin: 0;
    }
  }
}
