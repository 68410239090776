@use '../../../styles/breakpoints.module';

.versatile-card {
  $block: &;

  background-color: var(--component-versatile-card-background-color);
  border: 1px solid var(--color-neutrals-grey-5);
  border-radius: var(--space-2);
  box-sizing: border-box;
  display: flex;
  width: 100%;

  &__full-card-link {
    text-decoration: none;
  }

  &--clickable {
    height: 100%;
    @include breakpoints.for-large-up {
      &:hover {
        transform: scale(1.1);
        transition: transform 0.2s;
      }
    }
  }

  &__youtube {
    border-top-left-radius: var(--space-2);
    border-top-right-radius: var(--space-2);
  }

  &__favourite {
    align-items: center;
    background-color: rgb(0 0 0 / 60%);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: var(--space-8);
    justify-content: center;
    position: absolute;
    right: var(--space-2);
    top: var(--space-2);
    width: var(--space-8);

    &__icon {
      color: var(--color-neutrals-white);
    }

    &__icon-filled {
      color: var(--color-neutrals-white);
    }
  }

  &__top {
    flex-direction: column;
  }

  &__top-image-container {
    border-top-left-radius: var(--space-2);
    border-top-right-radius: var(--space-2);

    &--contained {
      padding: var(--space-6) var(--space-6) 0 var(--space-6);
    }

    > div {
      height: 100%;
    }

    img {
      width: 100%;
    }
  }

  &__bottom {
    flex-direction: column-reverse;
  }

  &__bottom-image-container {
    border-bottom-left-radius: var(--space-2);
    border-bottom-right-radius: var(--space-2);

    &--contained {
      padding: 0 var(--space-6) var(--space-6) var(--space-6);
    }

    > div {
      height: 100%;
    }

    img {
      width: 100%;
    }
  }

  &__left {
    align-items: center;
    flex-direction: column;

    #{$block}__content-container {
      width: 100%;
    }

    #{$block}__image-container {
      height: 134px;
      width: 100%;
    }

    @include breakpoints.for-medium-up {
      align-items: initial;
      flex-direction: row;

      #{$block}__content-container,
      #{$block}__image-container {
        height: auto;
        width: 50%;
      }
    }

    @include breakpoints.for-large-up {
      #{$block}__content-container {
        width: 70%;
      }
      #{$block}__image-container {
        width: 30%;
      }
    }
  }

  &__left-image-width-20 {
    #{$block}__content-container {
      width: initial;
    }
    #{$block}__image-container {
      width: 100%;

      &--contained {
        width: 20%;
      }
    }

    @include breakpoints.for-medium-up {
      #{$block}__content-container {
        width: 80%;
      }
    }
  }

  &__left-image-width-30 {
    #{$block}__content-container {
      width: initial;
    }
    #{$block}__image-container {
      width: 100%;

      &--contained {
        width: 30%;
      }
    }

    @include breakpoints.for-medium-up {
      #{$block}__content-container {
        width: 70%;
      }
    }
  }

  &__left-image-width-40 {
    #{$block}__content-container {
      width: initial;
    }
    #{$block}__image-container {
      width: 100%;

      &--contained {
        width: 40%;
      }
    }

    @include breakpoints.for-medium-up {
      #{$block}__content-container {
        width: 60%;
      }
    }
  }

  &__left-image-width-50 {
    #{$block}__content-container {
      width: initial;
    }
    #{$block}__image-container {
      width: 100%;

      &--contained {
        width: 50%;
      }
    }

    @include breakpoints.for-medium-up {
      #{$block}__content-container {
        width: 50%;
      }
    }
  }

  &__left-image-width-60 {
    #{$block}__content-container {
      width: 100%;
    }
    #{$block}__image-container {
      width: 100%;

      &--contained {
        width: 60%;
      }
    }

    @include breakpoints.for-medium-up {
      #{$block}__content-container {
        width: 40%;
      }
    }
  }

  &__left-image-width-70 {
    #{$block}__content-container {
      width: 100%;
    }
    #{$block}__image-container {
      width: 100%;

      &--contained {
        width: 70%;
      }
    }

    @include breakpoints.for-medium-up {
      #{$block}__content-container {
        width: 30%;
      }
    }
  }

  &__left-image-container {
    border-top-left-radius: var(--space-2);
    border-top-right-radius: var(--space-2);

    &--contained {
      padding: var(--space-6) var(--space-6) 0 var(--space-6);

      @include breakpoints.for-medium-up {
        padding: var(--space-6) 0 var(--space-6) var(--space-6);
      }

      img {
        height: auto !important; // For the image to be aligned to the top, it overrides NextImage inline style
      }
    }

    @include breakpoints.for-medium-up {
      border-bottom-left-radius: var(--space-2);
      border-top-left-radius: var(--space-2);
      border-top-right-radius: 0;
      flex: 1;
    }

    > div {
      height: 100%;
    }
  }

  &__right {
    align-items: center;
    flex-direction: column;

    #{$block}__content-container {
      width: 100%;
    }

    #{$block}__image-container {
      height: 134px;
      width: 100%;
    }

    @include breakpoints.for-medium-up {
      align-items: initial;
      flex-direction: row-reverse;

      #{$block}__content-container,
      #{$block}__image-container {
        height: auto !important; // So that the image fills the whole height instead of taking the height given from contentful and is used inline
        width: 50%;
      }
    }
    @include breakpoints.for-large-up {
      #{$block}__content-container {
        width: 70%;
      }
      #{$block}__image-container {
        width: 30%;
      }
    }
  }

  &__right-image-width-20 {
    #{$block}__content-container {
      width: initial;
    }
    #{$block}__image-container {
      width: 100%;

      &--contained {
        width: 20%;
      }
    }

    @include breakpoints.for-medium-up {
      #{$block}__content-container {
        width: 80%;
      }
    }
  }

  &__right-image-width-30 {
    #{$block}__content-container {
      width: initial;
    }
    #{$block}__image-container {
      width: 100%;

      &--contained {
        width: 30%;
      }
    }

    @include breakpoints.for-medium-up {
      #{$block}__content-container {
        width: 70%;
      }
    }
  }

  &__right-image-width-40 {
    #{$block}__content-container {
      width: initial;
    }
    #{$block}__image-container {
      width: 100%;

      &--contained {
        width: 40%;
      }
    }

    @include breakpoints.for-medium-up {
      #{$block}__content-container {
        width: 60%;
      }
    }
  }

  &__right-image-width-50 {
    #{$block}__content-container {
      width: initial;
    }
    #{$block}__image-container {
      width: 100%;

      &--contained {
        width: 50%;
      }
    }

    @include breakpoints.for-medium-up {
      #{$block}__content-container {
        width: 50%;
      }
    }
  }

  &__right-image-width-60 {
    #{$block}__content-container {
      width: initial;
    }
    #{$block}__image-container {
      width: 100%;

      &--contained {
        width: 60%;
      }
    }

    @include breakpoints.for-medium-up {
      #{$block}__content-container {
        width: 40%;
      }
    }
  }

  &__right-image-width-70 {
    #{$block}__content-container {
      width: 100%;
    }
    #{$block}__image-container {
      width: 100%;

      &--contained {
        width: 70%;
      }
    }

    @include breakpoints.for-medium-up {
      #{$block}__content-container {
        width: 30%;
      }
    }
  }

  &__right-image-container {
    border-top-left-radius: var(--space-2);
    border-top-right-radius: var(--space-2);

    &--contained {
      padding: var(--space-6) var(--space-6) 0 var(--space-6);

      @include breakpoints.for-medium-up {
        padding: var(--space-6) var(--space-6) var(--space-6) 0;
      }

      img {
        height: auto !important; // For the image to be aligned to the top, it overrides NextImage inline style
      }
    }

    @include breakpoints.for-medium-up {
      border-bottom-right-radius: var(--space-2);
      border-top-left-radius: 0;
      border-top-right-radius: var(--space-2);
      flex: 1;
    }

    > div {
      height: 100%;
    }
  }

  &__image-container {
    overflow: hidden;
    position: relative;

    img {
      object-fit: cover;
      object-position: center;
    }

    &--contained {
      width: auto;

      > div {
        height: 100%;
      }

      // These are needed to override NextImage inline styles
      img {
        object-fit: contain;
        position: relative !important;

        @include breakpoints.for-medium-up {
          position: absolute !important;
        }
      }
    }
  }

  &__image {
    &--mobile {
      display: block;

      @include breakpoints.for-medium-up {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include breakpoints.for-medium-up {
        display: block;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: var(--space-4);

    &--left {
      text-align: left;
    }

    &--center {
      text-align: center;
    }

    &--right {
      text-align: right;
    }

    &--space-between {
      height: 100%;
      justify-content: space-between;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      color: var(--component-versatile-card-title-color);
      margin-bottom: 0;
    }

    &--colour-primary {
      color: initial;
    }

    &--colour-white {
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: var(--color-neutrals-white);
      }
    }

    &--colour-black {
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: var(--color-neutrals-black);
      }
    }
  }

  &__content-description {
    display: flex;
    flex-direction: column;
    gap: var(--space-4);

    &--colour-primary {
      color: initial;
    }

    &--colour-white {
      color: var(--color-neutrals-white);
    }

    &--colour-black {
      color: var(--color-neutrals-black);
    }
  }

  &__content-container-ctas {
    display: flex;
    flex-direction: column;
    gap: var(--space-2);
  }

  &__content-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: var(--space-6);
    justify-content: space-between;
    padding: var(--space-6);

    p {
      margin-bottom: 0;
    }
  }

  &__content-container-link {
    a {
      padding: 0;
    }
  }

  &__content-container-buttons {
    display: flex;
    gap: var(--space-4);

    &--full-width {
      > a {
        width: 100%;
      }
    }

    &__image {
      img {
        object-fit: contain;
      }
    }
  }

  &__content-container-tertiary-button {
    display: flex;
    justify-content: center;
  }

  &--borderless {
    border: none;
    box-shadow: none;

    img {
      border-radius: var(--space-2);
    }
  }
}

.image-button {
  background: none;
  border: none;
  margin: 0;
  padding: 0px;

  &:hover {
    background: transparent;
    border: none;
    color: transparent;
    cursor: pointer;
    filter: none;
    text-decoration: none;
  }
}

.speedy-logo {
  @include breakpoints.for-medium-up {
    right: calc(var(--space-3) * -1);
    top: calc(var(--space-3) * -1);
  }
}
