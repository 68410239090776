@use '../../../styles/breakpoints.module';

.error-message {
  align-items: top;
  display: flex;
  flex-direction: row;
  gap: var(--space-1);

  &__icon {
    color: var(--base-input-error-color);
    font-size: var(--base-input-error-font-size);
    font-size: var(--base-input-icon-size);
  }

  &__message {
    color: var(--base-input-error-color);
    font-size: var(--base-input-error-font-size);
    margin: 0px;
  }
}
