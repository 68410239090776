.arrow {
  height: 16px;
  width: 16px;

  &::before {
    border-color: transparent;
    border-style: solid;
    content: '';
    position: absolute;
  }
}

.tooltip {
  background-color: var(--component-tooltip-background-color);
  border-radius: var(--space-1);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 10%);
  max-width: 250px;
  outline: 0;
  position: relative;
  transition-property: transform, visibility, opacity;
  white-space: normal;

  &-title {
    color: var(--component-tooltip-title-color);
    margin: 0 0 4px;
  }

  &-description {
    font-size: var(--text-sm);
    margin: 0;
  }

  &[data-inertia][data-state='visible'] {
    transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
  }

  &-content {
    padding: var(--space-2) var(--space-4);
    position: relative;
    z-index: 1;
  }

  &[data-placement^='top'] > .arrow {
    bottom: 0;

    &::before {
      border-top-color: var(--component-tooltip-background-color);
      border-width: 8px 8px 0;
      bottom: -7px;
      left: 0;
      transform-origin: center top;
    }
  }

  &[data-placement^='bottom'] > .arrow {
    top: 0;

    &::before {
      border-bottom-color: var(--component-tooltip-background-color);
      border-width: 0 8px 8px;
      left: 0;
      top: -7px;
      transform-origin: center bottom;
    }
  }

  &[data-placement^='left'] > .arrow {
    right: 0;

    &::before {
      border-left-color: var(--component-tooltip-background-color);
      border-width: 8px 0 8px 8px;
      right: -7px;
      transform-origin: center left;
    }
  }

  &[data-placement^='right'] > .arrow {
    left: 0;

    &::before {
      border-right-color: var(--component-tooltip-background-color);
      border-width: 8px 8px 8px 0;
      left: -7px;
      transform-origin: center right;
    }
  }
}
