@use '../../../styles/breakpoints.module';

.partner-tile {
  $block: &;

  p {
    margin-bottom: 0;
  }

  display: flex;
  position: relative;

  &--default {
    align-items: center;
    flex-direction: column;
    justify-content: start;
    text-align: center;

    > div {
      width: 100%;
    }
  }

  &--inline {
    align-items: center;
  }

  &__speedy {
    color: var(--component-speedy-color);
    font-size: var(--text-2xl);
    line-height: var(--text-base);
    position: absolute;
    right: var(--space-2);
    top: var(--space-2);
    z-index: 10;
  }

  &__image {
    height: 50px;
    margin-bottom: 1rem;
    position: relative;
    width: 100%;

    img {
      height: 100%;
      object-fit: contain;
    }

    #{$block}--inline & {
      margin-bottom: 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    min-height: 50px;

    #{$block}--inline & {
      justify-content: center;
      padding-left: var(--space-3);
    }
  }

  &__image-container-border {
    #{$block}--inline & {
      border-right: 1px solid var(--module-partner-tile-inline-seperator-color);
      padding-right: var(--space-3);
    }
  }

  &__content-name {
    color: var(--module-partner-tile-name-color);
    line-height: 2;
  }

  &__content-name--extra-small {
    font-size: var(--text-xs);
    min-height: 19px;
  }

  &__content-name--small {
    font-size: var(--text-sm);
    min-height: 24px;
  }

  &__content-name--medium {
    font-size: var(--text-sm);
    min-height: 32px;

    @include breakpoints.for-medium-up {
      font-size: var(--text-base);
    }
  }

  &__content-name--large {
    font-size: var(--text-base);

    @include breakpoints.for-medium-up {
      font-size: var(--text-xl);
    }
  }

  &__content-rate-prefix {
    font-size: var(--text-xs);
  }

  &__content-rate {
    align-items: center;
    display: flex;
    font-weight: 600;
    justify-content: center;

    &--inline {
      justify-content: flex-start;
    }

    &--highlighted {
      background-color: var(--color-avios-green-7);
      border-radius: var(--util-border-radius);
      color: var(--color-avios-green-2);
      padding: var(--space-1) var(--space-2);
    }

    &--highlighted-icon {
      font-size: var(--text-sm);
      margin-right: var(--space-1);
    }
  }

  &__content-rate--extra-small {
    font-size: var(--text-xs);
    min-height: 28px;
  }

  &__content-rate--small {
    font-size: var(--text-sm);
    min-height: 32px;
  }

  &__content-rate--medium {
    font-size: var(--text-sm);
    min-height: 40px;

    @include breakpoints.for-medium-up {
      font-size: var(--text-base);
    }
  }

  &__content-rate--large {
    font-size: var(--text-base);

    @include breakpoints.for-medium-up {
      font-size: var(--text-large);
    }
  }

  &__content-was-rate {
    color: var(--module-partner-tile-was-rate-color);
  }

  &__content-was-rate--extra-small {
    font-size: var(--text-2xs);
    min-height: 18px;
  }

  &__content-was-rate--small {
    font-size: var(--text-xs);
    min-height: 21px;
  }

  &__content-was-rate--medium {
    font-size: var(--text-xs);
    min-height: 24px;

    @include breakpoints.for-medium-up {
      font-size: var(--text-sm);
    }
  }

  &__content-was-rate--large {
    font-size: var(--text-sm);

    @include breakpoints.for-medium-up {
      font-size: var(--text-base);
    }
  }

  &__content-sub-fields {
    color: var(--module-partner-tile-sub-fields-color);
    line-height: 2;

    &__separator {
      margin: 0 10px;
    }
  }

  &__content-sub-fields--extra-small {
    font-size: var(--text-2xs);
  }

  &__content-sub-fields--small {
    font-size: var(--text-xs);
  }

  &__content-sub-fields--medium {
    font-size: var(--text-sm);

    @include breakpoints.for-medium-up {
      font-size: var(--text-base);
    }
  }

  &__content-sub-fields--large {
    font-size: var(--text-base);

    @include breakpoints.for-medium-up {
      font-size: var(--text-xl);
    }
  }
}
