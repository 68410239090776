@use '../../../styles/breakpoints.module';

.nav {
  background: var(--component-nav-background);
  display: flex;
  height: var(--space-12);

  @include breakpoints.for-medium-up {
    height: var(--space-14);
  }

  &__scrollable {
    align-items: stretch;
    display: flex;
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    overflow-x: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__static {
    align-items: stretch;
    box-shadow: 3px 0 4px -2px rgb(32 33 36 / 40%);
    display: flex;
    flex-shrink: 0;
    flex-wrap: nowrap;
    position: relative;
    z-index: 1;

    @include breakpoints.for-medium-up {
      box-shadow: none;
    }
  }

  &__link {
    align-items: center;
    color: var(--component-nav-link-color);
    column-gap: var(--space-2);
    display: flex;
    flex: 0 0 auto;
    padding: var(--space-4);
    text-decoration: var(--component-nav-link-text-decoration);

    &:hover {
      background: var(--component-nav-link-hover-background);
      color: var(--component-nav-link-hover-color);
    }

    &--active {
      background: var(--component-nav-link-active-background);
      color: var(--component-nav-link-active-color);
    }

    &--highlighted {
      color: var(--component-nav-link-highlighted-color);
    }
  }

  &__link-icon {
    font-size: var(--text-xl);
  }
}
