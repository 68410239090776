@use '../../styles/utils.module';

.selector {
  color: var(--component-selector-color);
  position: relative;

  &__label {
    color: var(--base-input-label-color);
    display: flex;
    font-size: var(--base-input-font-size);
    padding-bottom: var(--space-2);
    width: 100%;
  }

  &__button {
    background-color: transparent;
    border: 1px solid;
    border-color: var(--component-selector-border-color);
    border-radius: var(--space-1);
    box-sizing: border-box;
    color: var(--component-selector-color);
    cursor: pointer;
    display: flex;
    font-size: var(--text-sm);
    justify-content: flex-start;
    margin: 0;
    padding: 10px;
    position: relative;
    width: 100%;
  }

  &__button:hover {
    background-color: var(--component-selector-hover-background-color);
    border-color: var(--component-selector-border-color);
    color: var(--component-selector-hover-color);
  }

  &__button:focus {
    background-color: transparent;
    color: var(--component-selector-hover-color);
  }

  &__button-secondary {
    @include utils.base-input;

    &--placeholder {
      color: var(--base-input-placeholder-color) !important;
    }

    &:hover,
    &:active {
      background-color: var(--base-input-background);
      border: none;
      border-bottom: var(--base-input-border-bottom-focus);
      color: var(--base-input-color);
    }

    &:focus {
      background-color: var(--base-input-background);
      color: var(--base-input-color);
    }

    > span {
      flex: 1;
      justify-content: space-between;
    }
  }

  &__button-is-open {
    border-bottom: var(--base-input-border-bottom-focus);
    outline: var(--base-input-focus-outline);
    outline-offset: 2px;
  }

  &__button-icon {
    font-size: var(--text-xl);
    margin-left: var(--space-2);
    vertical-align: middle;
  }

  &__list {
    background-color: var(--component-selector-list-background-color);
    border: 1px solid;
    border-color: var(--component-selector-list-border-color);
    border-radius: var(--space-1);
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    text-decoration: none;
    width: 100%;
    z-index: 100;
  }

  &__list-secondary {
    border: none;
    box-shadow: var(--component-selector-box-shadow);
    margin-top: var(--space-4);
    max-height: 250px;
    overflow: auto;
  }

  &__list-item {
    background: transparent;
    border-radius: 0;
    box-sizing: border-box;
    color: var(--component-selector-item-color);
    cursor: pointer;
    display: block;
    font-size: var(--component-selector-list-font-size);
    padding: var(--space-2) var(--space-4);
    text-align: left;
    width: 100%;

    &--selected {
      background-color: var(--component-selector-selected-background);
    }

    &:hover,
    &:active {
      background-color: var(--component-selector-hover-background);
      color: var(--component-selector-item-color);
      filter: none;
    }
  }

  &__list-item-secondary {
    border: none;
    color: var(--base-input-color);
    font-size: var(--base-input-font-size);
    height: var(--space-12);

    &--selected {
      background-color: var(--color-neutrals-grey-5);
    }

    &:hover,
    &:active {
      background-color: var(--color-neutrals-grey-6);
      border: none;
      color: var(--base-input-color);
    }
  }

  &__error {
    color: var(--base-input-error-color);
    font-size: var(--base-input-error-font-size);
    padding-top: var(--space-2);
  }

  &__error-link {
    align-items: center;
    color: var(--base-input-error-color);
    display: inline-flex;
    font-size: var(--base-input-error-font-size);
    gap: var(--space-2);
    margin-left: var(--space-1);
    text-decoration: underline;
  }
}
