@use '../../../styles/breakpoints.module';

.iframe-body {
  aspect-ratio: 16 / 9;
  border: 1px solid transparent;
  border-radius: var(--util-border-radius);
  min-width: 336px;
  width: 100%;

  &__300h {
    @include breakpoints.for-large-up {
      height: 300px;
    }
  }

  &__400h {
    @include breakpoints.for-large-up {
      height: 400px;
    }
  }

  &__500h {
    @include breakpoints.for-large-up {
      height: 500px;
    }
  }

  &__600h {
    @include breakpoints.for-large-up {
      height: 600px;
    }
  }
}
