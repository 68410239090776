@use '../../../styles/breakpoints.module';

.button {
  $block: &;

  align-items: center;
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  font: inherit;
  font-weight: 500;
  justify-content: center;
  line-height: 1rem;
  padding: 0;
  text-align: center;
  text-decoration: none;
  user-select: none;

  &:hover {
    text-decoration: none;
  }

  &__wrapper {
    gap: 0.5em;
    &:has(#{$block}__top-icon) {
      gap: 0;
    }

    &--column {
      flex-direction: column;
      justify-content: start;
    }
  }

  &__leading-icon,
  &__trailing-icon,
  &__top-icon,
  &__bottom-icon,
  &__external-icon {
    transition: opacity var(--component-button-animation-speed);
    vertical-align: middle;
    #{$block}--loading & {
      opacity: 0;
    }

    &--primary {
      color: var(--component-button-custom-color-primary);
    }

    &--white {
      color: var(--component-button-custom-color-white);
    }

    &--black {
      color: var(--component-button-custom-color-black);
    }
  }

  &__top-icon {
    font-size: var(--text-2xl);
    padding: 0 var(--space-3) var(--space-3);
    transition: opacity var(--component-button-animation-speed);
    vertical-align: middle;

    @include breakpoints.for-medium-up {
      font-size: var(--text-4xl);
      padding: var(--space-3);
    }

    #{$block}--loading & {
      opacity: 0;
    }
  }

  &__loader {
    position: absolute;

    #{$block}--small & {
      height: 1.1em;
    }

    #{$block}--medium &,
    #{$block}--large & {
      height: 1.5em;
    }
  }

  &__label {
    align-items: center;
    display: flex;
    line-height: 1.5;
    transition: opacity var(--component-button-animation-speed);

    #{$block}--loading & {
      opacity: 0;
    }

    &--primary {
      color: var(--component-button-custom-color-primary);
    }

    &--white {
      color: var(--component-button-custom-color-white);
    }

    &--black {
      color: var(--component-button-custom-color-black);
    }

    &--xsmall {
      font-size: var(--component-button-xsmall-font-size);
    }

    &--small {
      font-size: var(--component-button-small-font-size);
    }

    &--medium {
      font-size: var(--component-button-medium-font-size);
    }

    &--large {
      font-size: var(--component-button-large-font-size);
    }

    &--xlarge {
      font-size: var(--component-button-xlarge-font-size);
    }

    &--xxlarge {
      font-size: var(--component-button-xxlarge-font-size);
    }
  }

  &--xsmall {
    border-radius: var(--component-button-xsmall-border-radius);
    font-size: var(--component-button-xsmall-font-size);
    padding: var(--component-button-xsmall-padding);

    &#{$block}--icon-only {
      padding: var(--component-button-icon-xsmall-padding);
    }
  }

  &--small {
    border-radius: var(--component-button-small-border-radius);
    font-size: var(--component-button-small-font-size);
    padding: var(--component-button-small-padding);

    &#{$block}--icon-only {
      padding: var(--component-button-icon-small-padding);
    }
  }

  &--medium {
    border-radius: var(--component-button-medium-border-radius);
    font-size: var(--component-button-medium-font-size);
    padding: var(--component-button-medium-padding);

    &#{$block}--icon-only {
      &:not(#{$block}--unstyled) {
        padding: var(--component-button-icon-medium-padding);
      }
    }
  }

  &--large {
    border-radius: var(--component-button-large-border-radius);
    font-size: var(--component-button-large-font-size);
    padding: var(--component-button-large-padding);

    &#{$block}--icon-only {
      padding: var(--component-button-icon-large-padding);
    }
  }

  &--xlarge {
    border-radius: var(--component-button-xlarge-border-radius);
    font-size: var(--component-button-xlarge-font-size);
    padding: var(--component-button-xlarge-padding);

    &#{$block}--icon-only {
      padding: var(--component-button-icon-xlarge-padding);
    }
  }

  &--xxlarge {
    border-radius: var(--component-button-xxlarge-border-radius);
    font-size: var(--component-button-xxlarge-font-size);
    padding: var(--component-button-xxlarge-padding);

    &#{$block}--icon-only {
      padding: var(--component-button-icon-xxlarge-padding);
    }
  }

  &--xxxlarge {
    border-radius: var(--component-button-xxlarge-border-radius);
    font-size: var(--component-button-xxxlarge-font-size);
    padding: var(--component-button-xxlarge-padding);

    &#{$block}--icon-only {
      padding: var(--component-button-icon-xxlarge-padding);
    }
  }

  &--contained {
    background: var(--component-button-contained-background);
    border: 1px solid var(--component-button-contained-border-color);
    color: var(--component-button-contained-color);
    text-decoration: var(--component-button-contained-text-decoration);

    &:hover {
      background: var(--component-button-contained-hover-background);
      border: 1px solid var(--component-button-contained-hover-border-color);
      color: var(--component-button-contained-hover-color);
      text-decoration: var(--component-button-contained-hover-text-decoration);
    }

    &:focus-visible {
      background: var(--component-button-contained-focus-background);
      border-color: var(--component-button-contained-focus-border-color);
      color: var(--component-button-contained-focus-color);
      outline-color: var(--component-button-contained-focus-outline-color);
      outline-offset: 4px;
      text-decoration: var(--component-button-contained-focus-text-decoration);
    }

    &:active {
      background: var(--component-button-contained-active-background);
      border-color: var(--component-button-contained-active-border-color);
      color: var(--component-button-contained-active-color);
      text-decoration: var(--component-button-contained-active-text-decoration);
    }
  }

  &--outlined {
    background: var(--component-button-outlined-background);
    border: 1px solid var(--component-button-outlined-border-color);
    border-color: var(--component-button-outlined-border-color);
    color: var(--component-button-outlined-color);
    text-decoration: var(--component-button-outlined-text-decoration);

    &:hover {
      background: var(--component-button-outlined-hover-background);
      border-color: var(--component-button-outlined-hover-border-color);
      color: var(--component-button-outlined-hover-color);
      text-decoration: var(--component-button-outlined-hover-text-decoration);
    }

    &:focus-visible {
      background: var(--component-button-outlined-focus-background);
      border-color: var(--component-button-outlined-focus-border-color);
      color: var(--component-button-outlined-focus-color);
      outline-color: var(--component-button-outlined-focus-outline-color);
      outline-offset: 4px;
      text-decoration: var(--component-button-outlined-focus-text-decoration);
    }

    &:active {
      background: var(--component-button-outlined-active-background);
      border-color: var(--component-button-outlined-active-border-color);
      color: var(--component-button-outlined-active-color);
      text-decoration: var(--component-button-outlined-active-text-decoration);
    }
  }

  &--text {
    background: var(--component-button-text-background);
    border: none;
    border-color: var(--component-button-text-border-color);
    color: var(--component-button-text-color);
    text-decoration: var(--component-button-text-text-decoration);

    #{$block}__label:hover {
      background: var(--component-button-text-hover-background);
      border-color: var(--component-button-text-hover-border-color);
      color: var(--component-button-text-hover-color);
      text-decoration: var(--component-button-text-hover-text-decoration);
    }
  }

  &--text-block {
    background: var(--component-button-text-block-background);
    border: none;
    border-color: var(--component-button-text-block-border-color);
    color: var(--component-button-text-block-color);
    text-decoration: var(--component-button-text-block-text-decoration);

    &:hover {
      background: var(--component-button-text-block-hover-background);
      border-color: var(--component-button-text-block-hover-border-color);
      color: var(--component-button-text-block-hover-color);
      text-decoration: var(--component-button-text-block-hover-text-decoration);
    }
  }

  &--danger {
    background: var(--component-button-danger-background);
    border: solid;
    border-color: var(--component-button-danger-border-color);
    color: var(--component-button-danger-color);
    text-decoration: var(--component-button-danger-text-decoration);

    &:hover {
      background: var(--component-button-danger-hover-background);
      border-color: var(--component-button-danger-hover-border-color);
      color: var(--component-button-danger-hover-color);
      text-decoration: var(--component-button-danger-hover-text-decoration);
    }

    &:focus-visible {
      background: var(--component-button-danger-focus-background);
      border-color: var(--component-button-danger-focus-border-color);
      color: var(--component-button-danger-focus-color);
      outline-color: var(--component-button-danger-focus-outline-color);
      outline-offset: 4px;
      text-decoration: var(--component-button-danger-focus-text-decoration);
    }

    &:active {
      background: var(--component-button-danger-active-background);
      border-color: var(--component-button-danger-active-border-color);
      color: var(--component-button-danger-active-color);
      text-decoration: var(--component-button-danger-active-text-decoration);
    }
  }

  &--tertiary {
    background: var(--component-button-tertiary-background);
    border: none;
    border-color: var(--component-button-tertiary-border-color);
    color: var(--component-button-tertiary-color);
    text-decoration: var(--component-button-tertiary-text-decoration);

    &:hover {
      background: var(--component-button-tertiary-hover-background);
      border-color: var(--component-button-tertiary-hover-border-color);
      color: var(--component-button-tertiary-hover-color);
      text-decoration: var(--component-button-tertiary-hover-text-decoration);
    }

    &:focus-visible {
      background: var(--component-button-tertiary-focus-background);
      border-color: var(--component-button-tertiary-focus-border-color);
      color: var(--component-button-tertiary-focus-color);
      outline-color: var(--component-button-tertiary-focus-outline-color);
      outline-offset: 4px;
      text-decoration: var(--component-button-tertiary-focus-text-decoration);
    }

    &:active {
      background: var(--component-button-tertiary-active-background);
      border-color: var(--component-button-tertiary-active-border-color);
      color: var(--component-button-tertiary-active-color);
      text-decoration: var(--component-button-tertiary-active-text-decoration);
    }
  }

  &--ghost {
    background: var(--component-button-ghost-background);
    border: solid;
    border-color: var(--component-button-ghost-border-color);
    color: var(--component-button-ghost-color);
    text-decoration: var(--component-button-ghost-text-decoration);

    &:hover {
      background: var(--component-button-ghost-hover-background);
      border-color: var(--component-button-ghost-hover-border-color);
      color: var(--component-button-ghost-hover-color);
      text-decoration: var(--component-button-ghost-hover-text-decoration);
    }

    &:focus-visible {
      background: var(--component-button-ghost-focus-background);
      border-color: var(--component-button-ghost-focus-border-color);
      color: var(--component-button-ghost-focus-color);
      outline-color: var(--component-button-ghost-focus-outline-color);
      outline-offset: 4px;
      text-decoration: var(--component-button-ghost-focus-text-decoration);
    }

    &:active {
      background: var(--component-button-ghost-active-background);
      border-color: var(--component-button-ghost-active-border-color);
      color: var(--component-button-ghost-active-color);
      text-decoration: var(--component-button-ghost-active-text-decoration);
    }
  }

  &--custom {
    background: var(--component-button-ghost-background);
    border: solid;
    border-color: var(--component-button-ghost-border-color);
    text-decoration: var(--component-button-ghost-text-decoration);

    #{$block}__label {
      font-size: var(--text-sm);
    }
    @include breakpoints.for-medium-up {
      #{$block}__label {
        font-size: var(--component-button-medium-font-size);
      }
    }

    &:has(#{$block}__top-icon) {
      padding: 0;
    }
    @include breakpoints.for-medium-up {
      &:hover #{$block}__top-icon,
      &:not(:has(#{$block}__top-icon)):hover {
        background: var(--component-button-ghost-hover-background);
        border-color: var(--component-button-ghost-hover-border-color);
        color: var(--component-button-ghost-hover-color);
        text-decoration: var(--component-button-ghost-hover-text-decoration);
      }

      &:focus-visible #{$block}__top-icon,
      &:not(:has(#{$block}__top-icon)):focus-visible {
        background: var(--component-button-ghost-focus-background);
        border-color: var(--component-button-ghost-focus-border-color);
        color: var(--component-button-ghost-focus-color);
        outline-color: var(--component-button-ghost-focus-outline-color);
        outline-offset: 4px;
        text-decoration: var(--component-button-ghost-focus-text-decoration);
      }

      &:active #{$block}__top-icon,
      &:not(:has(#{$block}__top-icon)):active {
        background: var(--component-button-ghost-active-background);
        border-color: var(--component-button-ghost-active-border-color);
        color: var(--component-button-ghost-active-color);
        text-decoration: var(--component-button-ghost-active-text-decoration);
      }

      &:hover #{$block}__top-icon,
      &:focus-visible #{$block}__top-icon,
      &:active #{$block}__top-icon {
        border-radius: 50%;
      }
    }
  }

  &--unstyled {
    background: none;
    border: none;
    display: inherit;
    padding: 0;
    text-decoration: none;

    &:focus-visible {
      background: none;
      border-color: none;
      outline-color: none;
      text-decoration: none;
    }

    &:active {
      background: none;
      border-color: none;
      text-decoration: none;
    }
  }

  &--disabled {
    opacity: var(--component-button-disabled-opacity);
    pointer-events: none;
  }

  &--loading {
    opacity: var(--component-button-loading-opacity);
    pointer-events: none;
  }
}
