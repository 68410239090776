@use '../../styles/breakpoints.module';

.header {
  background: var(--module-header-background);
  border-bottom: var(--module-header-border-bottom);
  color: var(--module-header-color);

  &__primary {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: var(--util-max-screen-size);
    padding: var(--space-3) var(--space-4);

    @include breakpoints.for-small-up {
      padding: var(--space-4) var(--space-6);
    }

    &-container {
      border-bottom: var(--module-header-primary-border-bottom);
    }
  }

  &__login {
    align-items: center;
    color: var(--module-header-login-color);
    display: flex;
    font-size: var(--text-2xl);
    text-decoration: none;

    &:hover {
      color: var(--module-header-login-hover-color);
    }
  }

  &__login-label {
    display: none;
    font-size: var(--text-base);

    @include breakpoints.for-large-up {
      display: block;
      margin-left: var(--space-2);
    }
  }

  &__nav {
    box-sizing: border-box;
    margin: 0 auto;
    max-width: var(--util-max-screen-size);
    padding: 0 var(--space-4);
    @include breakpoints.for-small-up {
      padding: 0 var(--space-6);
    }

    &-container {
      background: var(--component-nav-background);
    }
  }

  &__logo {
    display: none;

    > div {
      height: 48px;
      width: 249px;
    }

    img {
      object-fit: contain;
    }

    @include breakpoints.for-medium-up {
      display: block;
    }

    @include breakpoints.for-large-up {
      display: block;
    }
  }

  &__small-logo {
    > div {
      height: 25px;
      width: 100px;
    }

    img {
      object-fit: contain;
    }

    @include breakpoints.for-medium-up {
      display: none;
    }
  }

  &__user {
    display: flex;
    height: 100%;
    padding: 0;
    position: relative;

    > button {
      background-color: transparent;
      color: var(--module-header-loggedin-button-color);
    }

    > button:hover {
      background-color: transparent;
      color: var(--module-header-loggedin-button-color);
    }
  }

  &__user-details {
    position: absolute;
    right: 0;
    top: var(--space-14);
    z-index: 20;
  }
}

.avios-header {
  p {
    margin: unset;
  }

  & > header {
    max-height: 74px;
  }

  @media (min-width: 1024px) {
    min-height: 136px !important;

    & > header {
      max-height: 136px;
    }
  }
}

.shared-header {
  min-height: 54.56px;

  @media (min-width: 800px) {
    min-height: 171.06px;
  }
}
