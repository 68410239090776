@use '../../styles/breakpoints.module';

.locale-modal {
  &__wrapper {
    max-width: 343px;
    padding: var(--space-6);
  }

  &__title {
    color: var(--component-locale-model-title-color);
    margin-bottom: var(--space-6);
  }

  &__description {
    color: var(--color-neutrals-grey-1);
    font-size: var(--text-base);
    margin-bottom: var(--space-6);
  }

  &__locales-list {
    list-style-type: none;
    margin: 0;
  }

  &__locale {
    margin-bottom: var(--space-4);

    &__button {
      width: 100%;

      img {
        border: 1px solid var(--color-neutrals-grey-4);
      }
    }
  }
}
