.image-container {
  position: relative;

  img {
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    width: 100%;
  }
}
