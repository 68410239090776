@use '../../styles/breakpoints.module';

.member-details {
  background: var(--module-member-details-background);
  border-radius: var(--module-member-details-border-radius);
  box-shadow: var(--module-member-details-box-shadow);
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  font-size: var(--text-sm);
  position: relative;
  width: var(--module-member-details-width);

  h2,
  p {
    margin-bottom: 0;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: var(--space-6);
    padding: var(--space-6) var(--space-4);

    h2 {
      color: #707070;
      font-size: var(--text-base);
    }

    p {
      color: var(--module-member-details-headings-color);
      font-size: var(--text-2xl);
      letter-spacing: 1px;
      word-wrap: break-word;
    }
  }

  &__avios-icon {
    font-size: var(--text-2xl);
    margin-left: var(--space-2);
    margin-top: 5px;
    position: absolute;
  }

  &__list {
    background-color: #fafafa;
    border-radius: 0 0 10px 10px;
    list-style: none;
    margin: 0;

    li {
      border-top: 1px solid #ddd;
      padding: var(--space-2) 0;

      a {
        color: #333;
        margin: 0;

        span {
          font-size: var(--text-base);
        }

        [aria-label='icon'] {
          font-size: var(--text-2xl);
        }
      }
    }
  }

  &__close-btn {
    color: var(--module-member-details-close-button-color);
    padding: var(--space-4);
    position: absolute;
    right: 0;
    top: 0;

    &:hover {
      opacity: 0.5;
    }
  }

  &__cta:hover {
    color: var(--module-member-details-links-hover-color);
  }
}
