.qrcode-section {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--space-6);
  margin: var(--space-10) 0;
  max-height: 528px;
  text-align: center;
  width: 350px;

  p {
    margin: 0;
  }

  &__logo {
    height: 48px;
    width: 200px;

    > img {
      object-fit: contain;
    }
  }

  &__membership-id {
    color: var(--color-dark-cerulean);
    letter-spacing: 1px;
  }

  &__add-to-wallet {
    background-color: var(--color-neutrals-grey-6);
    bottom: 0;
    height: 72px;
    position: absolute;
    width: 100%;
  }

  &__qrcode {
    background-color: red;
    height: 180px;
    margin-top: 8px;
    width: 180px;
  }

  .error {
    &__title,
    &__icon {
      color: var(--module-qr-modal-membership-id-color);
    }

    &__icon {
      font-size: var(--text-7xl);
    }

    &__description {
      margin: var(--space-6) var(--space-9) 0;
    }
  }
}
