@use '../../styles/vars.module';

.download-app-prompt {
  animation: show-app-prompt 1s vars.$ease-1 0s forwards;
  background-color: var(--module-download-app-prompt-background);
  height: auto;
  left: 0;
  padding: var(--space-10) var(--space-4) var(--space-4);
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;

  &__container {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
  }

  &__sub-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: var(--space-4);
  }

  &__header {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__title {
    color: var(--module-download-app-prompt-title-color);
    font-size: var(--module-download-app-prompt-title-size);
    font-weight: var(--module-download-app-prompt-title-font-weight);
    margin-bottom: 0;
  }

  &__description {
    color: var(--module-download-app-prompt-description-color);
    font-size: var(--module-download-app-prompt-description-size);
    margin-bottom: 0;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    margin-top: var(--space-3);

    &__primary {
      background-color: var(
        --module-download-app-prompt-primary-cta-background
      );
      border: none;
      border-radius: var(--module-download-app-prompt-cta-border-radius);
      color: var(--module-download-app-prompt-primary-cta-color);
      font-size: var(--module-download-app-prompt-cta-size);
      line-height: var(--module-download-app-prompt-cta-line-height);
      padding: var(--space-1) var(--space-3) 6px;

      &:hover {
        background-color: var(
          --module-download-app-prompt-primary-cta-background
        );
        border: none;
        color: var(--module-download-app-prompt-primary-cta-color);
      }

      &:active {
        background-color: var(
          --module-download-app-prompt-primary-cta-background
        );
        color: var(--module-download-app-prompt-primary-cta-color);
      }
    }

    &__secondary {
      background-color: var(
        --module-download-app-prompt-secondary-cta-background
      );
      border: none;
      border-radius: var(--module-download-app-prompt-cta-border-radius);
      color: var(--module-download-app-prompt-secondary-cta-color);
      font-size: var(--module-download-app-prompt-cta-size);
      line-height: var(--module-download-app-prompt-cta-line-height);
      padding: var(--space-1) var(--space-3) 6px;

      &:hover {
        background-color: var(
          --module-download-app-prompt-secondary-cta-background
        );
        border: none;
        color: var(--module-download-app-prompt-secondary-cta-color);
      }

      &:active {
        background-color: var(
          --module-download-app-prompt-secondary-cta-background
        );
        color: var(--module-download-app-prompt-secondary-cta-color);
      }
    }
  }

  &__close-icon {
    color: var(--module-download-app-prompt-close-icon-color);
    font-size: var(--module-download-app-prompt-close-icon-size);
  }
}

@keyframes show-app-prompt {
  from {
    transform: translateY(-100%) translateZ(0);
  }

  to {
    transform: translateY(0) translateZ(0);
  }
}
