@use '../../styles/vars.module';

.cookie-banner {
  animation: show-cookie-notice 1s vars.$ease-1 0.5s forwards;
  background-color: var(--module-cookie-banner-background-color);
  bottom: 0;
  position: fixed;
  transform: translateY(100%) translateZ(0);
  width: 100%;
  z-index: 100;

  &__copy {
    color: var(--module-cookie-banner-text-color);
    font-size: var(--text-base);
    margin: var(--space-6) var(--space-6) 0;
    word-wrap: break-word;
  }

  &__button-group {
    background: transparent;
    margin: var(--space-6);
    margin-top: var(--space-4);
  }

  &__button-action {
    line-height: var(--text-2xl);
    padding: var(--space-3);
    padding-left: var(--space-4);

    &:first-of-type {
      background-color: var(--module-cookie-banner-text-color);
      color: var(--module-cookie-banner-background-color);
      margin-right: var(--space-2);
    }

    &:nth-child(2) {
      color: var(--module-cookie-banner-text-color);
    }
  }
}

@keyframes show-cookie-notice {
  from {
    transform: translateY(100%) translateZ(0);
  }

  to {
    transform: translateY(0) translateZ(0);
  }
}
