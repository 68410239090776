@use '../../../styles/breakpoints.module';

.section {
  background-position: center center;
  background-size: cover;

  &__inner {
    box-sizing: border-box;
    margin: 0 auto;
    max-width: var(--util-max-screen-size);
    padding: var(--space-6) var(--space-4);

    @include breakpoints.for-small-up {
      padding: var(--space-6) var(--space-6);
    }
  }

  &--full-bleed {
    > div {
      max-width: none;
      padding: 0;
    }
  }
}
