.loading-screen {
  &__page {
    background: rgba(0, 0, 0, 75%);
    display: flex;
    height: 100%;
    position: fixed;
    width: 100%;
    z-index: 100;
  }

  &__loader {
    height: 100px;
    margin: auto;
  }
}
