@use './../../../styles/breakpoints.module';

.modal {
  border: none;
  border-radius: var(--space-2);
  box-shadow: 4px 4px 4px rgb(0 0 0 / 10%);
  max-width: 350px;
  padding: 0;

  &::backdrop {
    background-color: rgba(0, 0, 0, 50%);
  }

  @include breakpoints.for-medium-up {
    max-width: 500px;
  }

  &__exit-button {
    align-items: center;
    background-color: rgb(0 0 0 / 60%);
    border: 0;
    border-radius: 50%;
    color: var(--color-neutrals-white);
    cursor: pointer;
    display: flex;
    font-size: var(--text-2xl);
    height: var(--space-8);
    justify-content: center;
    position: absolute;
    right: var(--space-2);
    top: var(--space-2);
    width: var(--space-8);
    z-index: 3;
  }
}
