.maintenance-page {
  align-items: center;
  display: flex;
  justify-content: center;
}

.maintenance-content {
  margin: var(--space-6);
}

.logo-container {
  height: 64px;
  margin-bottom: var(--space-8);
  position: relative;
  width: 288px;
}
