@use '../../../styles/breakpoints.module';

.error-banner {
  background: var(--module-partner-info-error-background);
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 20;

  &__container {
    margin: 0 auto;
    max-width: var(--util-max-screen-size);
    position: relative;
  }

  &__close {
    color: var(--color-neutrals-white);
    position: absolute;
    right: 0;
    top: 0;
  }

  &__title {
    color: var(--module-partner-info-error-text-color);
    margin: 0 auto var(--space-2);
    padding-right: var(--space-12);
  }

  &__text {
    color: var(--module-partner-info-error-text-color);
    font-size: var(--module-partner-info-error-text-size);
    margin-bottom: 0;
    padding-right: var(--space-12);
  }
}
