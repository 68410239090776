$icons: (
  'arrow-left': '\e900',
  'arrow-right': '\e901',
  'arrow-up-right': '\e902',
  'arrow-up': '\e903',
  'award': '\e904',
  'bell-off': '\e905',
  'bell': '\e906',
  'bookmark': '\e907',
  'calendar': '\e908',
  'camera-off': '\e909',
  'camera': '\e90a',
  'check-circle': '\e90b',
  'check': '\e90c',
  'chevron-down': '\e90d',
  'chevron-left': '\e90e',
  'chevron-right': '\e90f',
  'chevron-up': '\e910',
  'compass': '\e911',
  'copy': '\e912',
  'credit-card': '\e913',
  'external-link': '\e914',
  'heart-filled': '\e915',
  'eye-off': '\e916',
  'eye': '\e917',
  'filter': '\e918',
  'gift': '\e919',
  'heart': '\e91a',
  'home': '\e91b',
  'image': '\e91c',
  'info': '\e91d',
  'lock': '\e91e',
  'mail': '\e91f',
  'map-pin': '\e920',
  'map': '\e921',
  'menu': '\e922',
  'message-circle': '\e923',
  'more-horizontal': '\e924',
  'navigation': '\e925',
  'plus': '\e926',
  'printer': '\e927',
  'refresh-ccw': '\e928',
  'search': '\e929',
  'send': '\e92a',
  'settings': '\e92b',
  'sliders': '\e92c',
  'star': '\e92d',
  'tag': '\e92e',
  'thumbs-down': '\e92f',
  'thumbs-up': '\e930',
  'trash-2': '\e931',
  'logout': '\e932',
  'user': '\e933',
  'x-circle': '\e934',
  'x': '\e935',
  'zoom-in': '\e936',
  'zoom-out': '\e937',
  'arrow-down': '\e938',
  'speedy': '\e939',
  'desktop': '\e93a',
  'store': '\e93b',
  'whatsapp': '\e93c',
  'link': '\e93d',
  'avios-plectrum': '\e93e',
  'clock': '\e93f',
  'history': '\e940',
  'avios-missing': '\e941',
  'qrcode': '\e942',
  'exclamation': '\e943',
  'frown': '\e944',
  'error': '\e945',
  'success': '\e946',
  'warning': '\e947',
  'pause': '\e948',
  'play': '\e949',
  'upload': '\e94a',
  'bag-shopping': '\e94b',
  'cc-amex': '\e94c',
  'money-bill': '\e94d',
  'piggy-bank': '\e94e',
  'gifts': '\e94f',
  'hotel': '\e950',
  'plane-departure': '\e951',
  'shield-check': '\e952',
  'arrow-right-arrow-left': '\e953',
  'car-building': '\e954',
  'circle-up': '\e955',
  'laptop': '\e956',
  'burger-soda': '\e957',
  'car-side': '\e958',
  'circle-arrow-down-solid': '\e959',
  'clothes-hanger': '\e95a',
  'snowman': '\e95b',
  'suitcase': '\e95c',
  'wine-glass': '\e95d',
  'avios-plectrum-solid': '\e95e',
  'avios-missing-solid': '\e95f',
  'taxi-bus': '\e960',
  'sim-card': '\e961',
);

.icon {
  font-family: var(--font-estore) !important;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
  vertical-align: middle;

  &__background {
    background: url('/collect-avios/shopping/images/icons/plectrum.svg')
      no-repeat center center;
    background-size: contain;
  }

  @each $name, $glyph in $icons {
    &--#{$name}:before {
      content: $glyph;
    }
  }
}
