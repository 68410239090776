.loader {
  display: inline-flex;
  height: 100%;

  &__svg {
    animation: rotate 0.8s linear infinite;
    height: 100%;
    width: 100%;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
