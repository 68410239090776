@use './utils.module' as *;
@import './normalize.scss';

:host,
:root {
  background-color: var(--color-neutrals-grey-7);
  color: var(--base-font-color);
  font-family: var(--base-font-family);
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
  scroll-behavior: smooth;

  /* Text */
  --text-2xs: 0.6rem; /* 10px */
  --text-xs: 0.75rem; /* 12px */
  --text-sm: 0.875rem; /* 14px */
  --text-base: 1rem; /* 16px */
  --text-lg: 1.125rem; /* 18px */
  --text-xl: 1.25rem; /* 20px */
  --text-2xl: 1.5rem; /* 24px */
  --text-3xl: 1.75rem; /* 28px */
  --text-4xl: 2rem; /* 32px */
  --text-5xl: 2.25rem; /* 36px */
  --text-6xl: 3rem; /* 48px */
  --text-7xl: 3.75rem; /* 60px */
  --text-8xl: 4.5rem; /* 72px */
  --text-9xl: 6rem; /* 96px */
  --text-10xl: 8rem; /* 128px */

  /* space */
  --space-1: 0.25rem; /* 4px */
  --space-2: 0.5rem; /* 8px */
  --space-3: 0.75rem; /* 12px */
  --space-4: 1rem; /* 16px */
  --space-5: 1.25rem; /* 20px */
  --space-6: 1.5rem; /* 24px */
  --space-7: 1.75rem; /* 28px */
  --space-8: 2rem; /* 32px */
  --space-9: 2.25rem; /* 36px */
  --space-10: 2.5rem; /* 40px */
  --space-11: 2.75rem; /* 44px */
  --space-12: 3rem; /* 48px */
  --space-13: 3.25rem; /* 52px */
  --space-14: 3.5rem; /* 56px */
  --space-15: 3.75rem; /* 60px */
  --space-16: 4rem; /* 64px */
  --space-17: 4.25rem; /* 68px */
  --space-18: 4.5rem; /* 72px */
  --space-19: 4.75rem; /* 76px */
  --space-20: 5rem; /* 80px */
}

* {
  font-family: var(--base-font-family);
  text-rendering: optimizespeed;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--base-header-color);
  font-family: var(--base-headings-font-family);
  line-height: var(--base-headings-line-height);
}

h1 {
  font-size: var(--text-4xl);
  font-weight: var(--base-header-font-weight);
  margin: 0 0 var(--space-6);
}

h2 {
  font-size: var(--text-3xl);
  font-weight: var(--base-header-font-weight);
  margin: 0 0 var(--space-6);
}

h3 {
  font-size: var(--text-2xl);
  font-weight: var(--base-header-font-weight);
  margin: 0 0 var(--space-6);
}

h4 {
  font-size: var(--text-xl);
  font-weight: var(--base-header-font-weight);
  margin: 0 0 var(--space-6);
}

h5 {
  font-size: var(--text-base);
  font-weight: var(--base-header-font-weight);
  margin: 0 0 var(--space-4);
}

h6 {
  font-size: var(--text-sm);
  font-weight: var(--base-header-font-weight);
  margin: 0 0 var(--space-4);
}

hr {
  border-bottom: 0;
  border-top: 1px solid var(--color-neutrals-grey-6);
  margin: var(--space-6) 0;
}

p,
ul,
ol {
  margin: 0 0 var(--space-6);
  padding: 0;
}

ul,
ol {
  margin: 0 0 0 var(--space-4);
}

a {
  color: var(--color-mud-green);
  text-decoration: none;
}

::selection {
  background: var(--base-selection-background);
  color: var(--base-selection-color);
}

input {
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  font-size: 16px;
  outline: none;
  padding: 10px;
}

.legal-table {
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    border: 1px solid var(--color-neutrals-grey-4);
    padding: var(--space-3);
  }

  th {
    background-color: var(--color-neutrals-grey-6);
    color: var(--color-neutrals-grey-1);
  }
}

.collapse {
  color: var(--color-neutrals-grey-3);
  margin: var(--space-5) auto;
  position: relative;
  @include select-element(none);

  h2,
  h3 {
    align-items: center;
    border-bottom: 1px solid var(--color-neutrals-grey-5);
    cursor: pointer;
    display: flex;
    font-size: var(--text-lg);
    font-weight: var(--base-collapse-heading-font-weight);
    justify-content: space-between;
    margin: 0;
    padding-bottom: var(--space-5);

    &::after {
      content: '\e90d';
      display: block;
      font-family: var(--font-estore);
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-style: normal;
      font-variant: normal;
      font-weight: normal;
      line-height: 1;
      text-transform: none;
    }
  }

  > *:not(h2, h3) {
    display: none;
  }

  &.show {
    border-bottom: 1px solid var(--color-neutrals-grey-5);

    h2,
    h3 {
      padding-top: 0;

      &::after {
        content: '\e910';
        top: 3px;
      }
    }

    h2 + p,
    h3 + p {
      margin-top: var(--space-6);
    }

    > *:not(h2, h3) {
      display: block;
    }
  }

  &:first-of-type {
    padding-top: var(--space-4);

    h2,
    h3 {
      margin: 0;
    }
  }

  ul {
    margin-left: var(--space-10);
  }
}

.chevron-right {
  font-family: var(--font-estore);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  &::after {
    content: '\e90f';
  }
}
