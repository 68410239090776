@use '../../styles/breakpoints.module';

.search-bar {
  box-sizing: border-box;
  display: flex;
  max-width: 330px;
  position: relative;
  width: 100%;

  &__underlay {
    align-items: baseline;
    background-color: var(--module-search-bar-mobile-bgcolor);
    display: none;
    height: 100%;
    left: 0;
    margin-right: var(--space-2);
    max-width: 350px;
    position: fixed;
    top: var(--space-12);
    width: 100%;
    z-index: 20;

    @include breakpoints.for-medium-up {
      background-color: inherit;
      display: flex;
      margin: auto;
      padding: var(--space-2);
      padding: 0;
      position: relative;
      top: 0;
      z-index: 1;
    }
  }

  &__mobile-toggle {
    background: transparent;
    border: none;
    color: var(--module-search-bar-mobile-button-color);
    display: flex;
    font-size: 20px;
    margin-left: auto;
    margin-right: var(--space-2);
    padding: 0;

    @include breakpoints.for-medium-up {
      display: none;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  &__mobile-close {
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    font-size: 20px;
    margin-right: var(--space-2);
    padding: 0;

    &:hover {
      opacity: 0.5;
    }

    @include breakpoints.for-medium-up {
      display: none;
    }
  }

  &__container {
    display: flex;
    justify-content: center;
    margin: 0 var(--space-2);
    position: relative;
    width: 100%;

    @include breakpoints.for-medium-up {
      margin: auto;
      margin: 0 var(--space-4);
    }
  }

  &__text-box {
    background-color: var(--module-search-bar-input-bgcolor);
    border: 1px solid;
    border-color: var(--module-search-bar-input-border-color);
    border-radius: var(--module-search-bar-input-border-radius);
    box-sizing: border-box;
    caret-color: var(--module-search-bar-input-caret-color);
    color: var(--module-search-bar-input-color);
    padding-right: 30px;
    width: 100%;

    &::placeholder {
      color: var(--module-search-bar-input-placeholder-color);
    }

    &:focus {
      background-color: var(
        --module-search-bar-input-focus-bgcolor,
        var(--module-search-bar-input-bgcolor)
      );
      color: var(
        --module-search-bar-input-focus-color,
        var(--module-search-bar-input-color)
      );

      &::placeholder {
        color: var(
          --module-search-bar-input-focus-placeholder-color,
          var(--module-search-bar-input-placeholder-color)
        );
      }
    }
  }

  &__search-btn {
    background: transparent;
    border: none;
    color: var(--module-search-bar-button-color);
    cursor: pointer;
    font-size: 20px;
    padding: 0;
    position: absolute;
    right: 12px;
    transform: translateY(50%);

    &:hover {
      opacity: 0.5;
    }
  }

  &__result {
    background-color: var(--module-search-bar-result-mobile-bgcolor);
    box-sizing: border-box;
    left: 0;
    position: absolute;
    top: 41px;
    width: 100%;
    z-index: 20;

    @include breakpoints.for-medium-up {
      background-color: var(--module-search-bar-result-bgcolor);
      border: 1px solid;
      border-color: var(--module-search-bar-mobile-border-color);
      border-radius: 4px;
    }
  }

  &__result-item {
    background-color: var(--module-search-bar-mobile-bgcolor);
    color: var(--module-search-bar-result-item-color);
    cursor: pointer;
    display: block;
    padding: 10px 15px;
    text-decoration: none;

    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    @include breakpoints.for-medium-up {
      background-color: var(--module-search-bar-result-item-bgcolor);
    }

    &:hover {
      background-color: var(--module-search-bar-result-item-hover-bgcolor);
    }
  }

  &__mobile-visible {
    display: flex;
    padding-top: var(--space-3);

    .search-bar__text-box {
      background-color: var(
        --module-search-bar-mobile-input-bgcolor,
        var(--module-search-bar-input-bgcolor)
      );
      border-color: var(
        --module-search-bar-mobile-input-border-color,
        var(--module-search-bar-input-border-color)
      );
      caret-color: var(
        --module-search-bar-mobile-input-caret-color,
        var(--module-search-bar-input-caret-color)
      );
      color: var(
        --module-search-bar-mobile-input-color,
        var(--module-search-bar-input-color)
      );

      &::placeholder {
        color: var(
          --module-search-bar-mobile-input-placeholder-color,
          var(--module-search-bar-input-placeholder-color)
        );
      }
    }

    .search-bar__search-btn {
      color: var(
        --module-search-bar-button-mobile-color,
        var(--module-search-bar-button-color)
      );
    }
  }
}
